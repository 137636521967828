import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const NotFoundPage = (): JSX.Element => {
  return (
    <>
      <Helmet title='404 | Page Not Found' />
      <main
        style={{
          maxWidth: "600px",
          padding: "2rem 1rem",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <StaticImage src={"./../images/not-found.png"} alt='page not found' />
        <h1>Aww, snap! </h1>
        <p style={{ marginBottom: "2rem" }}>
          This page does not exist or may have moved to the wild.
        </p>
        <Link to='/' style={{ textDecoration: "underline" }}>
          Go Home
        </Link>
      </main>
    </>
  );
};

export default NotFoundPage;
